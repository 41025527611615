.sub_scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.sub_scrollbar::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 10px;
}
.sub_scrollbar::-webkit-scrollbar-thumb {
    background: #4d4d4d;
    border-radius: 10px;
}
.sub_scrollbar::-webkit-scrollbar-thumb:hover {
    background: #999999;
}


/* Buttons */
.btn {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 8px 15px;
    border-radius: 0;
    transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    font-family: primaryfontregular;
    font-size: 14px;
    border-radius: 25px;
}
@media screen and (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    text-decoration: none;
}
.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: none;
}
.btn.disabled,
.btn:disabled {
    opacity: 0.65;
    cursor: not-allowed;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
a.btn.disabled {
    pointer-events: none;
    cursor: not-allowed;
}
.btn_tranparent {
    color: #fff;
    background-color: tranparent;
    border-color: tranparent;
}
.btn_black2{
    color: #000;
    border: 2px solid #000;
}
.btn_black2:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
}
.btn_danger{
    color: #fff;
    background-color: var(--danger);
    border-color: var(--danger);
}
.btn_danger:hover {
    color: #fff;
    background-color: var(--dangerhover);
    border-color: var(--dangerhover);
}
.btn_danger.disabled,
.btn_danger:disabled {
    color: #fff;
    background-color: var(--danger);
    border-color: var(--danger);
    cursor: not-allowed;
    opacity: 0.6;
}
.btn_primary2{
    color: var(--primary);
    border: 2px solid var(--primary);
}
.btn_primary2:hover {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn_primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn_primary:hover {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn_primary.disabled,
.btn_primary:disabled {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    cursor: not-allowed;
    opacity: 0.6;
}
.showmoreresp{
    color: var(--primary);
    text-decoration: underline;
}
@media screen and (max-width:800px) {
    .filterbtnresp{
        background-color: transparent !important;
        color: var(--primary) !important;
        text-decoration: underline !important;
        border:none !important;
        font-weight: 600;
    }
}
.btn_secondary {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn_secondary:hover {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn_black {
    color: #fff;
    background-color: #000;
    border-color: #000;
}
.btn_black:hover {
    color: #fff;
    background-color: #a3a3a3;
    border-color: #a3a3a3;
}
.btn_secondary.disabled,
.btn_secondary:disabled {
    color: #fff;
    background-color: var(--secondary);
    border-color: var(--secondary);
    cursor: not-allowed;
    opacity: 0.6;
}
/* Inner Content */
.app_container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin: 0;

}
.app_main {
    position: relative;
    flex-direction: column;
    z-index: 9;
    background-color: #f2f2f2;
}
/* .app_main_innerbg{
    padding: 90px 0 30px !important;   
} */
.app_main_inner2 {
    padding: 160px 120px 20px !important;   
}
.app_main_inner22{
    padding-top: 220px !important;
}
@media screen and (max-width:1367px) {
    .app_main_inner2{
        padding: 160px 80px 20px !important;   
    }
}
@media screen and (max-width:1285px) {
    .app_main_inner2{
        padding: 160px 80px 20px !important;   

    }
}
@media screen and (max-width:1025px) {
    .app_main_inner2 {
        padding: 170px 40px 20px !important;   
    }
    .app_main_inner22{
        padding-top: 280px !important;
    }
}
@media screen and (max-width:800px){
    .app_main_inner2 {
        padding: 120px 10px 20px !important;   
    }
    .app_main_inner22{
        padding-top: 300px !important;
    }
}

.staticpagecontainer{
    /* padding: 0 60px; */
}
.staticpageinner{
    /* padding: 0 60px; */
}
.statictablecontainer{
    background: white; 
    padding: 20px;
    min-height: 50vh;
    height: auto;
}
@media screen and (max-width:800px) {
    .staticpageinner{
        /* padding: 0 20px; */
    }
    .staticpagecontainer{
        /* padding: 0 0 0 20px; */
    }
    .staticpagecontainertrans{
        /* padding: 0 20px 0 0 !important; */
    }
    .statictablecontainer{
        padding: 10px;
    }
}
/* Card */
.card {
    position: relative;
    display: flex;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 10px;
    padding: 0.5em 0.8em;
    transition: all 0.2s;
}
/* Responsive */
@media (max-width: 991.98px) {
    .app_main {
        display: block;
    }
}
@media (max-width: 800px) {
    body .card.mb-3 {
        margin-bottom: 15px !important;
    }
    body .card_header {
        height: auto;
        display: block;
        padding: 0.75rem 1.5rem;
        text-align: center;
    }
}
.sectiontitle {
    color: var(--primary);
    font-size: 30px;
    font-family: primaryfontmedium;
}
@media screen and (max-width: 500px) {
    .sectiontitle {
        font-size: 25px !important;
    }
}
.sectiontitle {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.sectiontitle span {
    position: relative;
    white-space: nowrap;
    transition: 0.3s;
    padding:0 10px; 
}
/* .sectiontitle span::after {
    content: '';
    position: absolute;
    width: 5px;
    height: 50px;
    bottom: -3px;
    left: 0;
    display: block;
    border-radius: 1px;
    z-index: 1000;
    background-color: var(--primary);
}
 */
